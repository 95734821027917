import {
	AUTH_ROUTES,
	SEARCH_ROUTES,
	VERIFICATION_ROUTES,
	DASHBOARD_ROUTES,
	LOAN_ROUTES
} from "../constants";
import { redirectIfAuthenticated } from "../middlewares";

function loadView(view) {
	return () => import(/* webpackChunkName: "about" */ `../views/${view}`);
}

export const Routes = [
	{ path: '/account/personal', redirect: '/account/personal/account' },
	{ path: '/account/documents', redirect: '/account/documents/individual' },
	{
		path: '*',
		name: '404',
		component: loadView('NotFound.vue')
	},
	{
		path: AUTH_ROUTES.SIGNUP,
		name: "Signup",
		component: loadView("auth/signup.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: AUTH_ROUTES.LOGIN,
		name: "Login",
		component: loadView("auth/login.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: AUTH_ROUTES.SETUP,
		name: "Setup",
		component: loadView("auth/setup-complete.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified'],
		}
	},
	{
		path: VERIFICATION_ROUTES.PHONE,
		name: "Verify Phone Number",
		component: loadView("verification/phone.vue"),
		meta: {
			requiresAuth: true,
			prevent: ['phoneNumberVerified'],
		}
	},
	{
		path: VERIFICATION_ROUTES.SALARY,
		name: "Verify Salary",
		component: loadView("verification/salary.vue"),
	},
	{
		path: VERIFICATION_ROUTES.EMAIL,
		name: "Verify Email Address",
		component: loadView("verification/verified-email.vue"),
	},
	{
		path: AUTH_ROUTES.FORGOT_PASSWORD,
		name: "Forgot Password",
		component: loadView("auth/forgot-password.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: AUTH_ROUTES.RESET_PASSWORD,
		name: "Reset Password",
		component: loadView("auth/reset-password.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: SEARCH_ROUTES.SEARCH_RESULT,
		name: "Search Results",
		component: loadView("dashboard/Search.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['guest'],
		}
	},
	{
		path: DASHBOARD_ROUTES.OVERVIEW,
		name: "Dashboard Overview",
		component: loadView("Home.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified', 'updatedProfile'],
		}
	},
	{
		path: LOAN_ROUTES.NANO_REPAY,
		name: "Repay Nano Loan",
		component: loadView("nano/Repay.vue"),
		meta: {
			requiresAuth: true,
			// authScope: ['phoneNumberVerified', 'updatedProfile'],
		}
	},
	{
		path: DASHBOARD_ROUTES.HISTORY,
		name: "Dashboard History",
		component: loadView("dashboard/History.vue"),
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified', 'updatedProfile'],
		}
	},
	{
		path: DASHBOARD_ROUTES.ACCOUNT,
		name: "Account",
		component: loadView("dashboard/Account.vue"),
		children: [
			{
				path: ':tab',
				component: loadView("dashboard/Account.vue")
			}
		],
		meta: {
			requiresAuth: true,
			authScope: ['phoneNumberVerified', 'updatedProfile'],
		}
	},
	{
		path: LOAN_ROUTES.NANO_LOANS,
		name: "Nano Loans",
		component: loadView("nano/Initiatenano.vue"),
	},
];
