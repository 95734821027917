import Vue from "vue";
import Vuex from "vuex";
import { ApiInstance as api } from "../utils";
// import mixpanel from 'mixpanel-browser';
// import Cookies from 'js-cookie';

Vue.use(Vuex);

const redirectPaths = {
	phoneNumberVerified: "/verification/phone",
	// emailVerified: "/verification/email",
};

export default new Vuex.Store({
	state: {
		status: "",
		nok: null,
		employment: null,
		token: localStorage.getItem("auth-token") || "",
		profile: {
			loaded: false,
		},
		completeProfile: {
			loaded: false,
		},
		loans: null,
		documents: null,
		documentType: null,
		missingTypes: [],
		ttl: 300,
		tourComplete: false,
		nanoApplication: null
	},
	mutations: {
		LOGIN_SUCCESS(state, token) {
			state.token = token;
		},
		SET_USER_PROFILE(state, data) {
			state.profile = data;
		},
		SET_USER_COMPLETE_PROFILE(state, data) {
			state.completeProfile = data;
		},
		SET_NEXT_OF_KIN(state, data) {
			state.nok = data;
		},
		SET_EMPLOYMENT(state, data) {
			state.employment = data;
		},
		SET_PHONE_VERIFICATION_TTL(state, data) {
			state.ttl = data;
		},
		SET_TOUR_COMPLETE(state, data) {
			state.tourComplete = data;
		},
		LOANS(state, data) {
			state.loans = data
		},
		DOCUMENTS(state, data) {
			state.documents = data
		},
		DOCUMENT_TYPE(state, data) {
			state.documentType = data
		},
		MISSING_TYPES(state, data) {
			state.missingTypes = data
		},
		NANO_APPLICATION(state, data) {
			state.nanoApplication = data
		}
	},
	actions: {
		async loginUser(ctx, data) {
			return new Promise((resolve, reject) => {
				api
					.post("/auth/login", data)
					.then((response) => {
						if (response.status) {
							// mixpanel.identify(data.email, {
							// 	'distinct_id': Cookies.get('mix_id')
							// });
							// mixpanel.track('Login successful', {
							// 	'distinct_id': Cookies.get('mix_id')
							// });
							const {
								phoneNumberVerified,
								// emailVerified,
								token,
							} = response.data?.data;

							ctx.commit("LOGIN_SUCCESS", token);
							api.modifyHeaders("Authorization", "Bearer " + token);
							localStorage.setItem("auth-token", token);

							if (!phoneNumberVerified)
								return resolve({
									status: true,
									pending: redirectPaths["phoneNumberVerified"],
								});
							// if (!emailVerified)
							// 	return resolve({
							// 		status: true,
							// 		pending: redirectPaths["emailVerified"],
							// 	});
							return resolve({ status: true });
						} else {
							return reject(response.error);
						}
					})
					.catch((error) => reject(error));
			});
		},

		async userProfile(ctx, data) {
			await api.modifyHeaders(
				"Authorization",
				"Bearer " + localStorage.getItem("auth-token")
			);
			await api.modifyHeaders(
				"Access-Control-Allow-Origin",
				"*"
			);
			return new Promise((resolve, reject) => {
				api
					.get("/user/profile", data)
					.then((response) => {
						if(response.data?.data?.updatedProfile) {
							if(window.location.pathname === '/setup/complete')
							return window.location.replace('/');
						}
						if (response.status) {
							ctx.commit("SET_USER_PROFILE", {
								...response.data.data,
								loaded: true,
							});
							return resolve(response.data.data);
						} else {
							if (!response.status) {
								ctx.commit("SET_USER_PROFILE", {
									loaded: false,
								});
							}
							return reject(response.error);
						}
					})
					.catch((error) => {
						if (error?.response?.status === 401) {
							ctx.commit("SET_USER_PROFILE", {
								loaded: false,
							});
						}
						return reject(error);
					});
			});
		},

		async userCompleteProfile(ctx, data) {
			await api.modifyHeaders(
				"Authorization",
				"Bearer " + localStorage.getItem("auth-token")
			);
			await api.modifyHeaders(
				"Access-Control-Allow-Origin",
				"*"
			);
			return new Promise((resolve, reject) => {
				api
					.get("/user/complete/profile", data)
					.then((response) => {
						if (response.status) {
							ctx.commit("SET_USER_COMPLETE_PROFILE", {
								...response.data.data,
								loaded: true,
							});
							if(response.data.data.nextOfKin) {
								ctx.commit("SET_NEXT_OF_KIN", {
									...response.data.data.nextOfKin,
									loaded: true
								})
							}
							if(response.data.data.__employmentDetails__.length > 0) {
								ctx.commit("SET_EMPLOYMENT", {
									...response.data.data.__employmentDetails__[0],
									loaded: true
								})
							}
							return resolve(response.data.data);
						} else {
							if (!response.status) {
								ctx.commit("SET_USER_COMPLETE_PROFILE", {
									loaded: false,
								});
							}
							return reject(response.error);
						}
					})
					.catch((error) => {
						if (error?.response?.status === 401) {
							ctx.commit("SET_USER_COMPLETE_PROFILE", {
								loaded: false,
							});
						}
						return reject(error);
					});
			});
		},

		getLoans(ctx) {
			api.modifyHeaders(
				"Authorization",
				"Bearer " + localStorage.getItem("auth-token")
			);
			api.modifyHeaders(
				"Access-Control-Allow-Origin",
				"*"
			);

			return new Promise((resolve, reject) => {
				api.get('/loans')
				.then(res => {
					if(res.status) {
						ctx.commit('LOANS', res.data?.data)
						return resolve({
							status: true,
							data: res.data?.data
						})
					}

					if(!res.status) {
						return reject({
							status: false,
							data: res
						})
					}
				})
				.catch(e => {
					return reject(e)
				})
			})
		},

		getDocumentTypes(ctx) {
			api.modifyHeaders(
				"Authorization",
				"Bearer " + localStorage.getItem("auth-token")
			);
			api.modifyHeaders(
				"Access-Control-Allow-Origin",
				"*"
			);

			return new Promise((resolve, reject) => {
				api.get('/documents/types')
				.then(res => {
					if(res.status) {
						ctx.commit('DOCUMENT_TYPE', res.data?.data)
						return resolve(res.data?.data)
					}

					if(!res.status) {
						return reject(res)
					}
				})
				.catch(e => {
					return reject(e)
				})
			})
		},

		getDocuments(ctx) {
			const typesNeeded = ['government-issued-identification-card', 'employment-letter', '6-month-bank-statement']

			api.modifyHeaders(
				"Authorization",
				"Bearer " + localStorage.getItem("auth-token")
			);
			api.modifyHeaders(
				"Access-Control-Allow-Origin",
				"*"
			);

			return new Promise((resolve, reject) => {
				api.get('/documents')
				.then(res => {
					if(res.status) {
						ctx.commit('DOCUMENTS', res.data?.data)
						const docsAvailable = res.data?.data.map(d => d.type.slug) 
						const missing = typesNeeded.filter(t => !docsAvailable.includes(t))
						ctx.commit('MISSING_TYPES', missing)
						return resolve(res.data?.data)
					}

					if(!res.status) {
						return reject(res)
					}
				})
				.catch(e => {
					return reject(e)
				})
			})
		},

		async checkTourComplete(ctx) {
			await api.modifyHeaders(
				"Authorization",
				"Bearer " + localStorage.getItem("auth-token")
			);
			await api.modifyHeaders(
				"Access-Control-Allow-Origin",
				"*"
			);
			return new Promise((resolve, reject) => {
				api.get("/user/check/profile/complete")
				.then(res => {
					ctx.commit("SET_TOUR_COMPLETE", res)
					return resolve(res)
				})
				.catch(e => {
					return reject(e)
				})
			})
		},
	},
	getters: {
		authToken: (state) => state.token,
		ttl: (state) => state.ttl,
	},
});
