<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        user: (state) => state.profile?.user,
      })
    },
    mounted() {
      if (this.user) {
        window.intercomSettings = {
          app_id: "gtg5p6p0",
          name: this.user.firstName + " " + this.user.lastName, // Full name
          email: this.user.email, // Email address
          created_at: new Date(), // Signup date as a Unix timestamp
        };
      } else {
        window.intercomSettings = {
          app_id: "gtg5p6p0",
          created_at: new Date(), // Signup date as a Unix timestamp
        };
      }

      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/gtg5p6p0'
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/gtg5p6p0";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    },
  };
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  /* transition: opacity 0.8s, transform 0.8s; */
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  /* transform: translateX(-80%); */
}
#app::-webkit-scrollbar {
  display: none;
}
</style>
