import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/tailwind.css";
import "./assets/scss/style.scss";
import OtpInput from "@bachdgvn/vue-otp-input";
import VueHead from "vue-head";
import Notie from "notie";
import "notie/dist/notie.min.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import mixpanel from 'mixpanel-browser';
import Mono from 'vue-mono'

Bugsnag.start({
	apiKey: process.env.VUE_APP_BUGSNAG_KEY,
	plugins: [new BugsnagPluginVue()],
	enabledReleaseStages: [ 'production', 'staging' ],
});

const bugsnagVue = Bugsnag.getPlugin("vue");
bugsnagVue.installVueErrorHandler(Vue);

Vue.component("v-otp-input", OtpInput);

Vue.config.productionTip = false;

Vue.use(Mono, { publicKey: process.env.VUE_APP_MONO_KEY })

Vue.use(VueHead);
mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY);


Vue.prototype.$notie = Notie;
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

