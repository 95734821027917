export * from ".";
import Store from "../store";

const publicRoutes = ["Forgot Password", "Reset Password", "Signup", "Login"];

export async function redirectIfAuthenticated(to, from, next) {
	try {
		const response = await Store.dispatch("userProfile");
		if (response.user?.id) {
      if (publicRoutes.includes(to.name)) return next("/");
			return next();
		} else return next();
	} catch (error) {
		let redirectTo = undefined;
		if (error.statusCode === 401) {
			if (publicRoutes.includes(to.name)) {
				return next();
			} else {
				redirectTo = {
					path: "/login",
				};
			}
		}
		return next(redirectTo);
	}
}
