export const BASE_API = process.env.VUE_APP_BASE_API;
export const APP_URL = process.env.VUE_APP_APP_URL;

export const API_ROUTES = {
  AUTH: {
    VERIFICATION: {
      PHONE: '/auth/verify/phone/initiate',
      PHONE_COMPLETE: '/auth/verify/phone/complete',
    }
  }
}

export const AUTH_ROUTES = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  SETUP: '/setup/complete',
  FORGOT_PASSWORD: '/password/forgot',
  RESET_PASSWORD: '/password/reset/:userId/:token',
}

export const VERIFICATION_ROUTES = {
  PHONE: '/verification/phone',
  EMAIL: '/verification/email/success',
  SALARY: '/verification/salary'
}

export const SEARCH_ROUTES = {
  SEARCH_RESULT: '/search'
}

export const DASHBOARD_ROUTES = {
  OVERVIEW: '/',
  HISTORY: '/history',
  ACCOUNT: '/account/:type',
  HELP: '/help'
}

export const LOAN_ROUTES = {
  NANO_LOANS: '/nano-loan',
  NANO_REPAY: '/loan/repay',
}
