import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";
import { initiatePhoneNumberVerification } from "../utils/phone";
import { Routes } from "./routes";
import { ApiInstance as api } from "../utils";
import mixpanel from 'mixpanel-browser';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: Routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

	if (requiresAuth) {
		const urlQuery = new URLSearchParams(window.location.search)
		const getQuery = Object.fromEntries(urlQuery.entries())
		
		try {
			api.modifyHeaders(
				"Authorization",
				"Bearer " + localStorage.getItem("auth-token")
			);
			api.modifyHeaders(
				"Access-Control-Allow-Origin",
				"*"
			);

			const response = await Store.dispatch("userProfile");

			if(to.name === 'Verify Phone Number') {
				if (getQuery.bvn !== undefined) {
					return next()
				}

				if (response.phoneNumberVerified) {
					return next('/')
				}
				if (location.pathname === '/verification/phone') return next();
				if (from.name === "Login") {
					return next()
				} else return next({ path: '/login' });	
			}

			if (!response.phoneNumberVerified) {
				if (to.meta.authScope && to.meta.authScope.includes('phoneNumberVerified')) {
					const res = await initiatePhoneNumberVerification(response.user.id);
					if (res.status === 'success') {
						Store.commit('SET_PHONE_VERIFICATION_TTL', res.ttl);
						return next({ path: '/verification/phone', query: { redirect: to.fullPath }, });
					}
				}
			}

			if (!response.updatedProfile) {
				if (to.meta.authScope && to.meta.authScope.includes('updatedProfile')) {
					return next('/setup/complete');
				}
			}

			if (!response.emailVerified) {
				if (to.meta.authScope && to.meta.authScope.includes('emailVerified')) {
					return next('/verification/email');
				}
			}

			if(!Store.state.completeProfile.loaded) {
				await Store.dispatch("userCompleteProfile");
			}

			if(!['Setup', 'Verify Phone Number', 'Search Results', 'Repay Nano Loan'].includes(to.name)) {
				if (to.name === 'Dashboard Overview') {
					if (Store.state.loans === null) Store.dispatch('getLoans')
					if (Store.state.documents === null || Store.state.documentType === null) {
						Store.dispatch('getDocumentTypes')
						Store.dispatch('getDocuments')
					}
					return next()
				}
	
				if (Store.state.loans === null) {
					const res = await Store.dispatch('getLoans')
					if (res.data.items.length === 0) return next('/')
				}
	
				if (Store.state.nok === null) return next('/')
				if (Store.state.employment === null) return next('/')
	
				if (Store.state.documents === null || Store.state.documentType === null) {
					Store.dispatch('getDocumentTypes')
					Store.dispatch('getDocuments')
					if (Store.state.missingTypes.length > 0) return next('/')
					return next()
				}
			}

			return next();
		} catch (error) {
			if (error) {
				console.log(error)
				if(to.name === 'Verify Phone Number') {
					if (location.pathname === '/verification/phone') return next();
					if (from.name === 'Login') {
						return next()
					} else return next({ path: '/login' });
				}
				localStorage.removeItem('auth-token');
				mixpanel.reset()
				if (to.meta.authScope && to.meta.authScope.includes('guest')) {
					return next()
				}
				return next({
					path: "/login",
					query: { redirect: to.fullPath },
				});
			}
			return next();
		}
	} else {
		next();
	}
});

export default router;
